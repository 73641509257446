import React from "react";
import nestImg from "../../images/icon-nest.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Navbar.css";
import { useState } from "react";

function Navbar() {
  const [activeSection, setActiveSection] = useState("home");

  const handleNavClick = (section) => {
    console.log(`Navigation to ${section}`);
    setActiveSection(section);
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg top_navbar">
        <div className="container">
          {/* Logo */}
          <a
            className="navbar-brand d-flex align-items-center nav_icon"
            href="#home"
          >
            <img src={nestImg} alt="Nest Icon" className="img-fluid nav_icon" />
          </a>

          {/* Toggler for Mobile View */}
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}

          {/* Navigation Links */}
          <div className="collapse navbar-collapse  d-flex nav_list" id="navbarNav">
            <ul className="d-flex gap-3 mx-auto list-inline list-unstyled ">
              <li
                className={`list-inline-item ${
                  activeSection === "home" ? "active" : ""
                }`}
                onClick={() => handleNavClick("home")}
              >
                Home
              </li>
              <li
                className={`list-inline-item ${
                  activeSection === "property_info" ? "active" : ""
                }`}
                onClick={() => handleNavClick("property_info")}
              >
                Property Info
              </li>
              <li
                className={`list-inline-item ${
                  activeSection === "life_at" ? "active" : ""
                }`}
                onClick={() => handleNavClick("life_at")}
              >
                Life At
              </li>
              <li
                className={`list-inline-item ${
                  activeSection === "property_insight" ? "active" : ""
                }`}
                onClick={() => handleNavClick("property_insight")}
              >
                Property Insight
              </li>
              <li
                className={`list-inline-item ${
                  activeSection === "about_us" ? "active" : ""
                }`}
                onClick={() => handleNavClick("about_us")}
              >
                About Us
              </li>
              <li
                className={`nav-item ${
                  activeSection === "our_services" ? "active" : ""
                }`}
                onClick={() => handleNavClick("our_services")}
              >
                Our Services
              </li>
            </ul>

            {/* Contact Us Button */}
            <div className="d-flex">
              <button className="contact_us_top">
                CONTACT US
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
