import './App.css';
import Gallery from './components/explore-gallery/Gallery.js';
import FooterPage from './components/footer-page/FooterPage.js';
import JungleHome from './components/Jungle-home/JungleHome.js';
import Navbar from './components/navbar/Navbar.js';


function App() {
  return (
    <div className="App">
     <JungleHome/>
     {/* <Gallery/> */}
     {/* <Navbar/> */}
     {/* <FooterPage/> */}
    </div>
  );
}

export default App;
