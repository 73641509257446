import React from "react";
import "../Jungle-home/JungleHome.css";
import vectorImg from "../../images/Vector.png";
import propertyImg from "../../images/property.png";
import birdImg from "../../images/birdsImg.png";
import topleafImg from "../../images/top-leaf.svg";
import stickImg from "../../images/stick.png";
import homeImage from "../../images/homeSVG.svg";
import secondLetterImg from "../../images/secondImg.png";
import secondBtmImg from "../../images/sec-bottom.svg";
import insightFirst from "../../images/insight-top-1.svg";
import insightSecond from "../../images/insight-top-2.svg";
import insightMoun from "../../images/insight-mountain.svg";
import tree from "../../images/tree.svg";
import insightHome from "../../images/insight-home.svg";
import compoundPath from "../../images/compound-path.svg";
import leftDeerImage from "../../images/left-deer.png";
import leftLeaf from "../../images/left-leaf.png";
import dotImage from "../../images/dot.svg";
import groupImage from "../../images/group.svg";
import rightDeer from "../../images/right-deer.svg";
import rightLeaf from "../../images/right-leaf.svg";
import topGroupImg from "../../images/about-group.svg";
import map from "../../images/map.svg";
import mapBottom from "../../images/map-btm.svg";
import cloudTop from "../../images/cloud-top.svg";
import cloudImage from "../../images/cloud.svg";
import serviceMountain from "../../images/service-mountain.svg";
import serviceBtm from "../../images/service-bottom.svg";
import btmLogo from "../../images/btm-logo.svg";
import face from "../../images/link-fb.png";
import insta from "../../images/link-insta.png";
import linked from "../../images/link-linked.png";
import twitter from "../../images/link-twitter.svg";
import uTube from "../../images/link-tube.png";
import mail from "../../images/mail.png";
import app from "../../images/whatsapp.svg";
import line from "../../images/line.png";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../navbar/Navbar";

const JungleHome = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      {/* HOME PAGE */}
      <div className="home_container" id="home">
        <Navbar />
        <div className="center_text">
          <div className="discover_text">
            <span className="discover">DISCOVER</span>
            <span className="highlight">YOUR OASIS IN NATURE</span>
          </div>
          <div className="discover_sub_text">
            <span className="discover_highlight">Jungle View Homes: </span>Where
            Serenity Meets Luxury
          </div>
          <div className="explore_text">EXPLORE OUR PLOTS</div>
        </div>
      </div>
      {/* PROPERTY INFO PAGE  */}
      <div className="property_container" id="property_info">
        <div className="btm_img">
          <img src={vectorImg} alt="Vector-Picture"></img>
        </div>
        <div className="property_img">
          <img src={propertyImg} alt="Property-Picture"></img>
          <div className="bird_img">
            <img src={birdImg} alt="Bird-Picture"></img>
          </div>
          <div className="property_text">
            <div className="who_text">
              <span className="highlight">WHO</span>
              <span className="highlight_sub_text"></span>WE ARE
            </div>
            <div className="para_text">
              <p>
                At Jungle View Homes, we believe in creating a harmonious living
                experience that blends luxury with nature.{" "}
              </p>
              <p>
                Nestled in the heart of a breathtaking forest, our plots offer a
                unique opportunity to build your dream home surrounded by the
                beauty of the wild.Where Serenity Meets Luxury
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* LIFE AT PAGE  */}
      <div className="lifeAt_container" id="life_at">
        <div className="top_leaf_image">
          <img src={topleafImg} alt="Top-Leaf"></img>
        </div>
        <div className="life_txt">
          <span className="highlight">Why Choose</span> Jungle View Homes?
        </div>
        <div className="letter_img">
          <img src={secondLetterImg} alt="Letter-Picture"></img>
        </div>
        <div className="life_center_box">
          <div className="box_align">
            <div className="stick_image">
              <img src={stickImg} alt="Stick-Picture"></img>
            </div>
            <div className="para_grp_text">
              <p className="para1">
                <span className="highlighted">Natural Beauty & </span> Green
                <span className="highlighted"> Fresh Air</span>
              </p>
              <p className="para2">
                Live surrounded by serene landscapes and breathe in unpolluted
                air, far from city chaos.{" "}
              </p>
            </div>
          </div>
          <div className="homeImg">
            <img src={homeImage} alt="Home-Picture"></img>
          </div>
        </div>
        <div className="second-btm-img">
          <img src={secondBtmImg} alt="Frame-Picture"></img>
        </div>
      </div>
      {/* PROPERTY INSIGHT */}
      <div className="insight_container" id="property_insight">
        <div className="insight_Img_1">
          <img src={insightFirst} alt="Insight-First"></img>
        </div>
        <div className="insight_Img_2">
          <img src={insightSecond} alt="Insight-Second"></img>
        </div>
        <div className="insight_txt">
          <p className="insight_txt_1">
            <span className="highlighted">A Home in Harmony with Nature</span>
          </p>
          <p className="insight_txt_2">
            Every villa at Jungle View Homes is uniquely crafted to embrace the
            site’s natural contours and features, ensuring minimal impact on the
            environment. Instead of altering the landscape, each home is
            positioned to celebrate it, creating a sense of living within nature
            rather than simply near it. Trees, slopes, and views remain
            untouched, framing every villa in a unique, picturesque setting.
          </p>
        </div>
        <div className="insight_img_container">
          <div className="mountain_img">
            <img src={insightMoun} alt="Insight-Mountain"></img>
          </div>

          <div className="insight_home">
            <img src={insightHome} alt="Insight-Home"></img>
          </div>
          <div className="tree_img">
            <img src={tree} alt="Tree" />
          </div>
          <div className="compound_path">
            <img src={compoundPath} alt="Compound-Path"></img>
            <div className="dot_image">
              <img src={dotImage} alt="Dot" />
            </div>
          </div>
          <div className="left_deer_img">
            <img src={leftDeerImage} alt="Deer" />
          </div>
        </div>
        <div className="group_image">
          <img src={groupImage} alt="Group" />
        </div>
        <div className="left_leaf">
          <img src={leftLeaf} alt="Leaf"></img>
        </div>
        <div className="leaf_and_deers">
          <div className="right_deer">
            <img src={rightDeer} alt="Deer-2"></img>
          </div>
          <div className="right_leaf">
            <img src={rightLeaf} alt="Left-2"></img>
          </div>
        </div>
      </div>
      {/* ABOUT US */}
      <div className="about_container" id="about_us">
        <div className="top_about">
          <img src={topGroupImg} alt="Group"></img>
        </div>
        <div className="map_img">
          <div className="map_top_img">
            <img src={map} alt="Map"></img>
          </div>
          <div className="map_btm_img">
            <img src={mapBottom} alt="Map-Bottom"></img>
          </div>
        </div>
        <div className="text_about_center">
          <div className="about_text_1">Your Gateway to Nature</div>
          <div className="about_text_2">
            Located just minutes from the city, Jungle View Homes offers the
            perfect escape without sacrificing accessibility. Enjoy hiking
            trails, wildlife watching, and the serenity of forest living while
            being close to urban amenities.
          </div>
        </div>
      </div>
      {/* OUR SERVICE */}
      <div className="service_container" id="our_services">
        <div className="cloud_image">
          <img src={cloudImage} alt="Cloud" className="image-fluid"></img>
        </div>
        <div className="cloud-top">
          <img src={cloudTop} alt="Cloud-Top"></img>
        </div>
        <div className="service_text">
          <p className="service_text_1">
            Ready to<span className="highlighted"> Embrace Nature?</span>
          </p>
          <p className="service_text_2">
            Don’t miss your chance to secure a piece of this tranquil paradise.
          </p>
          <p className="service_text_3">
            Contact us today for more information!
          </p>
          <div className="contact">
            <div className="contact_us">CONTACT US</div>
            <div className="schedule">SCHEDULE A VISIT</div>
          </div>
        </div>
        <div className="mountain_image">
          <img src={serviceMountain} alt="Mountain" />
          <div className="service_btm">
            <img src={serviceBtm} alt="Service"></img>
          </div>
          <div className="links_container">
            <div className="three-column-container">
              <div className="column">
                <div className="nested-row">
                  <div className="btmLogo">
                    <img src={btmLogo} alt="LOGO"></img>
                  </div>
                </div>
                <div className="nested-row">
                  <div className="social_link">
                    <div className="social_link_1">
                      <img src={face} alt="FaceBook"></img>
                    </div>
                    <div className="social_link_2">
                      <img src={insta} alt=""></img>
                    </div>
                    <div className="social_link_3">
                      <img src={linked} alt=""></img>
                    </div>
                    <div className="social_link_4">
                      <img src={twitter} alt=""></img>
                    </div>
                    <div className="social_link_5">
                      <img src={uTube} alt=""></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="quick_link">QUICK LINKS</div>
                <div className="link_type">About Us</div>
                <div className="link_type">Contact</div>
                <div className="link_type">Privacy Policy</div>
              </div>
              <div className="column">
                <div className="nested-row">
                  <div className="email_app">
                    <img src={mail} alt="E-mail"></img>
                    <div>Email AT</div>
                  </div>
                  <div className="email_details">Vijay@jungleviewhomes.com</div>
                </div>
                <div className="nested-row">
                  <div className="email_app">
                    <img src={app} alt="WhatsApp"></img>
                    <div>General Enquiry</div>
                  </div>
                  <div className="app_details">+91 8867064507</div>
                </div>
              </div>
            </div>
            <div className="line">
              <img src={line} alt="Line"></img>
            </div>
            <div className="footer">
              <div className="details">
                <div className="copy">© {currentYear} Jungle View Homes</div>
                <div className="right">All Rights Reserved</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JungleHome;
